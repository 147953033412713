.michaelc-landing-img {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  object-fit: cover;
  object-position: center center;
}

.michaelc-landing-img-dimmer {
  background-color: #000000;
  bottom: 2px;
  left: 0;
  opacity: .5;
  position: absolute;
  right: 0;
  top: 0;;
}

.michaelc-about-me-img {
  max-width: 250px;
  height: auto;
  border-radius: 50%;
  margin: auto;
}