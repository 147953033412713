.michaelc-navbar-list-item-link {
    font-weight: 200;
    letter-spacing: 3.5px;
    text-transform: uppercase;
    color: #ffffff;
    text-decoration: none;
    font-size: 18px;
}

.michaelc-navbar-list-item-link:hover {
    text-decoration: underline;
    color: -webkit-link;
}